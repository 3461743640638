import { ReactNode, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { signOut } from 'aws-amplify/auth'

import {
  BuildingOfficeIcon,
  CalculatorIcon,
  ListBulletIcon,
  PlusCircleIcon,
  UserGroupIcon,
  UserPlusIcon
} from '@heroicons/react/16/solid'

import { ClipLoader, CreateProjectModal, Dialog, SalaryCalculator, usePersonnel, useUser } from '@/components'

import SidebarNav from './SidebarNav'

const dropdownItems = [
  {
    label: 'Company Profile',
    href: '/company-profile',
    icon: <BuildingOfficeIcon />
  },
  {
    label: 'Invite User',
    href: '/invite-user',
    icon: <UserPlusIcon />
  }
]

const CompanyNav = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname()
  const router = useRouter()
  const { setUser, setCompanyProfile, loading } = useUser()
  const { setPersonnel } = usePersonnel()

  const [showCalculatorModal, setShowCalculatorModal] = useState<boolean>(false)
  const [showCreateProjectModal, setShowCreateProjectModal] = useState<boolean>(false)

  const items = [
    {
      label: 'New Project',
      href: '/new-project',
      onClick: () => setShowCreateProjectModal(true),
      icon: <PlusCircleIcon />
    },
    {
      label: 'Projects',
      href: '/projects',
      icon: <ListBulletIcon />
    },
    {
      label: 'Team',
      href: '/team',
      icon: <UserGroupIcon />
    },
    {
      label: 'Calculator',
      href: '/salary-calculator',
      onClick: () => setShowCalculatorModal(true),
      icon: <CalculatorIcon />
    }
  ]

  const logout = async () => {
    try {
      await signOut()
      sessionStorage.clear()
      setUser(null)
      setCompanyProfile(null)
      setPersonnel([])
      router.push('/auth/login')
    } catch (error) {
      alert(error)
    }
  }

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ClipLoader />
      </div>
    )
  }

  return (
    <>
      <SidebarNav
        homePath="/dashboard"
        currentPath={pathname}
        sidebarItems={items}
        dropdownItems={dropdownItems}
        logout={logout}
      >
        {children}
      </SidebarNav>
      {showCalculatorModal && (
        <Dialog open size="5xl" bgColor="!white" onClose={() => setShowCalculatorModal(false)}>
          <SalaryCalculator />
        </Dialog>
      )}
      {showCreateProjectModal && (
        <CreateProjectModal projectName="" handleClose={() => setShowCreateProjectModal(false)} />
      )}
    </>
  )
}

export default CompanyNav
