import { Table, TableBody, TableCell, TableRow } from '@/components/catalyst'
import { formatPercent } from '@/components/salary-calculator/lib/numbro'

import { resourceType } from './schemas'
import { TABLE_CONFIG } from './config'

interface Values {
  totalBillRate: number
  totalSalary: number
  totalFullyBurdenedRate: number
  totalRevenue: number
  totalHourlyProfit: number
  totalProfit: number
}

const defaultValues: Values = {
  totalBillRate: 0,
  totalSalary: 0,
  totalFullyBurdenedRate: 0,
  totalRevenue: 0,
  totalHourlyProfit: 0,
  totalProfit: 0
}

const ViewSelfGuidedResourcePlanTable = ({ resourcePlan }: { resourcePlan: resourceType[] }) => {
  const rowValues: Values[] = Array(resourcePlan.length)
    .fill(null)
    .map(() => ({ ...defaultValues }))

  const tableTotals: Values = { ...defaultValues }

  TABLE_CONFIG.map((config) => {
    const { key, aggregateKey, compute } = config

    if (aggregateKey) {
      resourcePlan.forEach((resource, index) => {
        const value = compute ? compute(resource) : Number(resource[key as keyof typeof resource])

        tableTotals[aggregateKey as keyof typeof tableTotals] += value

        rowValues[index][aggregateKey as keyof Values] = value
      })
    }
  })

  return (
    <>
      <Table className="self-guided-resource-plan-table" grid dense>
        <TableBody>
          {TABLE_CONFIG.map((config) => {
            const { label, key, aggregateKey, compute, format } = config

            return (
              <TableRow key={label}>
                <TableCell className="font-bold">{label}</TableCell>
                <TableCell className="font-bold">
                  {aggregateKey ? format(tableTotals[aggregateKey as keyof typeof tableTotals]) : ''}
                </TableCell>
                {resourcePlan.map((resource, index) => {
                  return (
                    <TableCell key={`resource-${index}`}>
                      {format
                        ? format(compute ? compute(resource) : Number(resource[key as keyof typeof resource]))
                        : resource[key as keyof typeof resource]}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
          <TableRow className="bg-smet-green-light-6">
            <TableCell className="font-bold !bg-smet-green-light-6">Profit Margin</TableCell>
            <TableCell className="font-bold !bg-smet-green-light-6">
              {formatPercent(tableTotals.totalProfit / tableTotals.totalRevenue || 0)}
            </TableCell>
            {resourcePlan.map((resource, index) => {
              return (
                <TableCell key={`resource-${index}`}>
                  {formatPercent(rowValues[index].totalProfit / rowValues[index].totalRevenue || 0)}
                </TableCell>
              )
            })}
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

export default ViewSelfGuidedResourcePlanTable
