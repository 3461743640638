import { ReactNode } from 'react'

import { Text } from '@/components/catalyst'

import { DEFAULT_BILLABLE_HOURS, DEFAULT_WORKING_HOURS } from './lib/constants'
import { formatPercent, formatCurrency } from './lib/numbro'

import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  CalendarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  BriefcaseIcon
} from '@heroicons/react/24/outline'

const getHeaderAttributeDisplay = (icon: ReactNode, title: string, content: string) => {
  const titleDisplay = (
    <div className="flex items-center">
      {icon}
      <Text>{title}</Text>
    </div>
  )

  return (
    <div>
      {titleDisplay}
      <div>{content}</div>
    </div>
  )
}

const Header = ({
  potentialSalary,
  lowerSalaryRange,
  higherSalaryRange
}: {
  potentialSalary: PotentialSalary
  lowerSalaryRange: number
  higherSalaryRange: number
}) => {
  const { percentProfit } = potentialSalary

  const percentProfitDisplay = formatPercent(percentProfit)
  const lowerSalaryRangeDisplay = formatCurrency(lowerSalaryRange)
  const higherSalaryRangeDisplay = formatCurrency(higherSalaryRange)

  return (
    <div className="mb-4 space-y-4 md:flex md:justify-between md:space-y-0">
      {getHeaderAttributeDisplay(
        percentProfit < 0 ? (
          <ArrowTrendingDownIcon className="w-4 h-4 mr-1 text-zinc-500" />
        ) : (
          <ArrowTrendingUpIcon className="w-4 h-4 mr-1 text-zinc-500" />
        ),
        'Profit',
        percentProfitDisplay
      )}
      {getHeaderAttributeDisplay(
        <CalendarIcon className="w-4 h-4 mr-1 text-zinc-500" />,
        'Working Hours',
        DEFAULT_WORKING_HOURS.toString()
      )}
      {getHeaderAttributeDisplay(
        <ClockIcon className="w-4 h-4 mr-1 text-zinc-500" />,
        'Billable Hours',
        DEFAULT_BILLABLE_HOURS.toString()
      )}
      {getHeaderAttributeDisplay(<BriefcaseIcon className="w-4 h-4 mr-1 text-zinc-500" />, 'Planned Leave', '2 Weeks')}
      {getHeaderAttributeDisplay(
        <CurrencyDollarIcon className="w-4 h-4 mr-1 text-zinc-500" />,
        'Salary Range',
        `${lowerSalaryRangeDisplay} - ${higherSalaryRangeDisplay}`
      )}
    </div>
  )
}

export default Header
