import { ReactNode, useEffect } from 'react'
import { DefaultValues, useForm, UseFormReset } from 'react-hook-form'
import { z, ZodObject, ZodEffects } from 'zod'
import { Tooltip } from 'react-tooltip'

import { zodResolver } from '@hookform/resolvers/zod'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

import {
  Field,
  Label,
  Input,
  Select,
  Textarea,
  CheckboxGroup,
  CheckboxField,
  RadioGroup,
  RadioField
} from '@/components/catalyst'
import { ErrorMessage } from '@/components/catalyst/fieldset'

interface InputConfig {
  field: 'input'
  type?: string
  readOnly?: boolean
}

interface TextareaConfig {
  field: 'textarea'
  rows?: number
  resizable?: boolean
  readOnly?: boolean
}

interface Option {
  name: string
  value: string
}

interface SelectConfig {
  field: 'select'
  options: Option[]
}

interface CheckboxGroupConfig {
  field: 'checkbox-group'
  options: Option[]
}

interface RadioGroupConfig {
  field: 'radio-group'
  options: Option[]
}

type FieldConfig = InputConfig | TextareaConfig | SelectConfig | CheckboxGroupConfig | RadioGroupConfig

interface Field {
  name: string
  label: string
  tooltip?: string | ReactNode
  config: FieldConfig
}

const ZodForm = <T extends ZodObject<any> | ZodEffects<any>>({
  id,
  schema,
  fields,
  defaultValues,
  onSubmit
}: {
  id: string
  schema: T
  defaultValues?: DefaultValues<z.infer<T>>
  fields: Field[]
  onSubmit?: (data: z.infer<T>, reset: UseFormReset<z.TypeOf<T>>) => void
}) => {
  type FormValues = z.infer<T>
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: 'onChange'
  })

  const renderLabel = (field: Field) => {
    const { name, label, tooltip } = field

    if (tooltip) {
      return (
        <div className="flex items-center">
          <Label className="mr-1">{label}</Label>
          <InformationCircleIcon className="text-smet-blue h-4 w-4" data-tooltip-id={`${name}-${label}-tooltip`} />
          <Tooltip id={`${name}-${label}-tooltip`} opacity="1">
            <p className="max-w-xs">{tooltip}</p>
          </Tooltip>
        </div>
      )
    }

    return <Label>{label}</Label>
  }

  const renderField = (field: Field) => {
    if (field.config.field === 'input') {
      return (
        <Input type={field.config.type || 'text'} {...register(field.name as any)} readOnly={field.config.readOnly} />
      )
    } else if (field.config.field === 'textarea') {
      return (
        <Textarea
          {...register(field.name as any)}
          rows={field.config?.rows}
          resizable={field.config?.resizable}
          readOnly={field.config.readOnly}
        />
      )
    } else if (field.config.field === 'checkbox-group') {
      return (
        <CheckboxGroup>
          {field.config.options.map((option) => {
            return (
              <CheckboxField key={option.value}>
                <input type="checkbox" value={option.value} {...register(field.name as any)} />
                <Label className="mt-1">{option.name}</Label>
              </CheckboxField>
            )
          })}
        </CheckboxGroup>
      )
    } else if (field.config.field === 'radio-group') {
      return (
        <RadioGroup>
          {field.config.options.map((option) => {
            return (
              <RadioField key={option.value}>
                <input type="radio" value={option.value} {...register(field.name as any)} />
                <Label className="mt-1">{option.name}</Label>
              </RadioField>
            )
          })}
        </RadioGroup>
      )
    }

    return (
      <Select {...register(field.name as any)}>
        {field.config.options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          )
        })}
      </Select>
    )
  }

  const handleFormSubmit = (data: FormValues) => {
    if (onSubmit) {
      onSubmit(data, reset)
    }
  }

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
    }
  }, [defaultValues, reset])

  return (
    <form className="space-y-4" id={id} onSubmit={handleSubmit(handleFormSubmit)}>
      {fields.map((field) => {
        return (
          <Field key={field.name}>
            {renderLabel(field)}
            {renderField(field)}
            {errors[field.name as any]?.message && (
              <ErrorMessage>{errors[field.name as any]?.message as any}</ErrorMessage>
            )}
          </Field>
        )
      })}
    </form>
  )
}

export default ZodForm
