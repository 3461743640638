import { ArrowUturnLeftIcon, DocumentDuplicateIcon, PencilSquareIcon } from '@heroicons/react/16/solid'

import { Card } from '@/components'
import { Button, Text } from '@/components/catalyst'

import ViewSelfGuidedResourcePlanTable from './ViewSelfGuidedResourcePlanTable'
import { resourcePlanType } from './schemas'

const ViewSelfGuidedResourcePlanCard = ({
  resourcePlan,
  footerText,
  handleEdit,
  handleSaveResourcePlan,
  handleCopy,
  handleRevert
}: {
  resourcePlan?: resourcePlanType
  footerText?: string
  handleEdit?: () => void
  handleSaveResourcePlan?: () => void
  handleCopy?: () => void
  handleRevert?: () => void
}) => {
  return (
    <>
      <Card
        title={
          <div className="flex justify-between">
            <div>{resourcePlan?.name || 'Resource Plan'}</div>
            {resourcePlan?.startDate && resourcePlan?.endDate && (
              <div className="font-normal">
                {new Date(resourcePlan.startDate).toLocaleDateString('en-US', { timeZone: 'UTC' })} to{' '}
                {new Date(resourcePlan.endDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}
              </div>
            )}
          </div>
        }
        footer={
          <div className="flex items-center justify-between">
            <Text>{footerText}</Text>
            <div className="flex space-x-2 items-center">
              {handleCopy && (
                <Button className="smet-inverted-button" onClick={handleCopy}>
                  <span>Compare Plan</span> <DocumentDuplicateIcon />
                </Button>
              )}
              {handleSaveResourcePlan && (
                <Button className="smet-primary-button sm:h-auto sm:w-auto" onClick={handleSaveResourcePlan}>
                  <span>Save</span>
                  <svg
                    className="h-5 w-5 sm:h-4 sm:w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                  >
                    <path d="M18 2.25c.2 0 .39.08.53.22l3 3c.14.14.22.33.22.53v15c0 .41-.34.75-.75.75H3a.75.75 0 0 1-.75-.75V3c0-.41.34-.75.75-.75h15ZM17 12H7a1 1 0 0 0-1 1v7.25h12V13a1 1 0 0 0-1-1Zm-.5-8.25h-9V8a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3.75Zm-2.25.75c.41 0 .75.34.75.75v1.5a.75.75 0 1 1-1.5 0v-1.5c0-.41.34-.75.75-.75Z" />
                  </svg>
                </Button>
              )}
              {handleEdit && (
                <Button className="flex justify-center items-center smet-primary-button" onClick={handleEdit}>
                  <span>Edit</span>
                  <PencilSquareIcon />
                </Button>
              )}
              {handleRevert && (
                <Button className="flex justify-center items-center smet-inverted-button" onClick={handleRevert}>
                  <span>Revert to this Plan</span>
                  <ArrowUturnLeftIcon />
                </Button>
              )}
            </div>
          </div>
        }
      >
        <ViewSelfGuidedResourcePlanTable resourcePlan={resourcePlan?.resourcePlan || []} />
      </Card>
    </>
  )
}

export default ViewSelfGuidedResourcePlanCard
