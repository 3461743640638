'use client'

import { useState } from 'react'

import { AiAssistedResourcePlanTable, Card, Notification, SaveResourcePlanModal } from '@/components'
import { Button, Text } from '@/components/catalyst'

const AiAssistedResourcePlanCard = ({
  projectKey,
  resourcePlan,
  projectName,
  totals
}: {
  projectKey: string
  resourcePlan: Resource[]
  projectName?: string
  totals?: ResourcePlanTotals
}) => {
  const [isCreatingProject, setIsCreatingProject] = useState<boolean>(false)
  const [showNotification, setShowNotification] = useState<boolean>(false)

  const saveResourcePlan = async () => {
    const projectId = sessionStorage.getItem(projectKey)

    try {
      const res = await fetch(`api/projects/${projectId}/resource-plans`, {
        method: 'POST',
        body: JSON.stringify({
          resourcePlan,
          totals
        })
      })

      if (res.ok) {
        setShowNotification(true)
      } else {
        const error = await res.json().then((json) => json.error)
        throw new Error(error)
      }
    } catch (error) {
      alert(error)
    }
  }

  const handleSaveResourcePlan = () => {
    const projectId = sessionStorage.getItem(projectKey)

    if (projectId) {
      saveResourcePlan()
    } else {
      setIsCreatingProject(true)
    }
  }

  const handleResourcePlanModalClose = (projectId?: string) => {
    if (projectId) {
      sessionStorage.setItem(projectKey, projectId)
      handleSaveResourcePlan()
    }

    setIsCreatingProject(false)
  }

  return (
    <>
      <Card
        title="Resource Plan"
        footer={
          resourcePlan.length > 0 ? (
            <div className="flex justify-end space-x-2">
              <Button className="cursor-pointer" onClick={handleSaveResourcePlan}>
                Save
              </Button>
            </div>
          ) : null
        }
      >
        {resourcePlan.length > 0 ? (
          <>
            <Text className="mb-4">Here is the resource plan generated for {projectName}</Text>
            <AiAssistedResourcePlanTable resourcePlan={resourcePlan} totals={totals} />
          </>
        ) : (
          <Text>
            Your Assistant will generate a resource plan for you when it is able to, based on the input you give it.
          </Text>
        )}
      </Card>
      {isCreatingProject && (
        <SaveResourcePlanModal
          projectName={projectName || ''}
          handleClose={(projectId?: string) => handleResourcePlanModalClose(projectId)}
        />
      )}
      {showNotification && (
        <Notification
          title="Success!"
          text="Resource plan was successfully created."
          handleClose={() => setShowNotification(false)}
        />
      )}
    </>
  )
}

export default AiAssistedResourcePlanCard
