import { ReactNode } from 'react'
import { Badge, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/catalyst'

import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from '@heroicons/react/24/outline'

import { PROFIT_GRAY_COLOR, PROFIT_GREEN_COLOR, PROFIT_RED_COLOR } from './lib/constants'
import { formatCurrency, formatPercent } from './lib/numbro'

const getProfitIcon = (percentProfit: number) => {
  let icon

  if (percentProfit === 0) {
    icon = <MinusIcon className="hidden lg:block w-4 h-4" color={PROFIT_GRAY_COLOR} />
  } else if (percentProfit > 0) {
    icon = <ArrowUpIcon className="hidden lg:block w-4 h-4" color={PROFIT_GREEN_COLOR} />
  } else {
    icon = <ArrowDownIcon className="hidden lg:block w-4 h-4" color={PROFIT_RED_COLOR} />
  }

  return <span className="hidden lg:flex">{icon}</span>
}

const getProfitBadge = (profit: number, children: ReactNode) => {
  const color = profit === 0 ? PROFIT_GRAY_COLOR : profit < 0 ? PROFIT_RED_COLOR : PROFIT_GREEN_COLOR

  return <Badge style={{ backgroundColor: color }}>{children}</Badge>
}

const getPercentProfitDisplay = (percentProfit: number) => {
  return (
    <div className="flex space-x-2 items-center">
      {getProfitIcon(percentProfit)}
      {getProfitBadge(percentProfit, formatPercent(percentProfit))}
    </div>
  )
}

const getProjectedProfitDisplay = (profit: number) => {
  return (
    <div className="flex space-x-2 items-center">
      {getProfitIcon(profit)}
      {getProfitBadge(profit, formatCurrency(profit))}
    </div>
  )
}

const SalaryTable = ({ data }: { data: PotentialSalary[] }) => {
  const salaryTableData = data.map((salary) => {
    return {
      ...salary,
      salary: formatCurrency(salary.salary),
      percentProfit: getPercentProfitDisplay(salary.percentProfit),
      hourlyRate: formatCurrency(salary.hourlyRate, 2),
      fullyBurdenedRate: formatCurrency(salary.fullyBurdenedRate, 2),
      fullyBurdenedCost: formatCurrency(salary.fullyBurdenedCost),
      projectedProfit: getProjectedProfitDisplay(salary.projectedProfit),
      maxProjectedProfit: getProjectedProfitDisplay(salary.maxProjectedProfit)
    }
  })

  return (
    <div className="salary-table">
      <Table grid dense>
        <TableHead>
          <TableRow className="whitespace-pre-wrap">
            <TableHeader>Proposed Salary</TableHeader>
            <TableHeader>Profit (%)</TableHeader>
            <TableHeader>Hourly Rate</TableHeader>
            <TableHeader>Fully Burdened Rate</TableHeader>
            <TableHeader>Fully Burdened Cost</TableHeader>
            <TableHeader>Projected Profit</TableHeader>
            <TableHeader>Max Projected Profit</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {salaryTableData.map((row, index) => {
            return (
              <TableRow key={`tr-${index}`}>
                <TableCell>{row.salary}</TableCell>
                <TableCell>{row.percentProfit}</TableCell>
                <TableCell>{row.hourlyRate}</TableCell>
                <TableCell>{row.fullyBurdenedRate}</TableCell>
                <TableCell>{row.fullyBurdenedCost}</TableCell>
                <TableCell>{row.projectedProfit}</TableCell>
                <TableCell>{row.maxProjectedProfit}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default SalaryTable
