import { Dialog, ZodForm } from '@/components'
import { Button } from '@/components/catalyst'
import { CreateProjectSchema, CreateProjectType } from '@/utils/schemas'

const saveResourcePlanFormFields = [
  {
    name: 'projectName',
    label: 'Project Name',
    config: {
      field: 'input' as const,
      type: 'text'
    }
  },
  {
    name: 'projectDescription',
    label: 'Project Description',
    config: {
      field: 'textarea' as const,
      rows: 4,
      resizable: false
    }
  }
]

const SaveResourcePlanModal = ({
  projectName,
  handleClose
}: {
  projectName: string
  handleClose: (projectId?: string) => void
}) => {
  const createProject = async (data: CreateProjectType) => {
    const { projectName, projectDescription } = data

    try {
      const res = await fetch('api/projects', {
        method: 'POST',
        body: JSON.stringify({
          projectName,
          projectDescription
        })
      })

      if (res.ok) {
        let projectId

        await res.json().then((json) => {
          projectId = json.data.projectId
        })

        handleClose(projectId)
      } else {
        const error = await res.json().then((json) => json.error)
        throw new Error(error)
      }
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Dialog
      open
      title="Create Project"
      description="In order to save a resource plan, you must first create a project."
      actions={
        <>
          <Button plain onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button className="smet-primary-button" type="submit" form="create-project-form">
            Create
          </Button>
        </>
      }
      onClose={() => handleClose()}
    >
      <ZodForm
        id="create-project-form"
        fields={saveResourcePlanFormFields}
        defaultValues={{ projectName }}
        schema={CreateProjectSchema}
        onSubmit={createProject}
      />
    </Dialog>
  )
}

export default SaveResourcePlanModal
