import { ReactNode } from 'react'

import { ClipLoader } from '@/components'
import { Heading } from '@/components/catalyst'

const Page = ({
  title,
  headerSlot,
  loading,
  children
}: {
  title: string
  headerSlot?: ReactNode
  loading?: boolean
  children?: ReactNode
}) => {
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center">
          <ClipLoader />
        </div>
      ) : (
        <div className="space-y-6">
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading>{title}</Heading>
            {headerSlot}
          </div>
          {children}
        </div>
      )}
    </>
  )
}

export default Page
