'use client'

import { useState, FocusEvent } from 'react'

import { Heading } from '@/components/catalyst'

import { getCalculations, getSalaryRanges } from './lib/calculations'
import {
  DEFAULT_BILL_RATE,
  DEFAULT_LEAVE_ADJUSTED_HOURS,
  DEFAULT_PROPOSED_SALARY,
  DEFAULT_WRAP_RATE
} from './lib/constants'
import { validations } from './lib/form'
import Form from './Form'
import Header from './Header'
import ProfitChart from './ProfitChart'
import SalaryTable from './SalaryTable'

import './SalaryCalculator.css'

const formDefaults: CalculatorFormValues = {
  proposedSalary: DEFAULT_PROPOSED_SALARY.toString(),
  wrapRate: DEFAULT_WRAP_RATE.toString(),
  billRate: DEFAULT_BILL_RATE.toString(),
  leaveAdjustedHours: DEFAULT_LEAVE_ADJUSTED_HOURS.toString()
}

const errorDefaults: CalculatorFormValues = {
  proposedSalary: '',
  wrapRate: '',
  billRate: '',
  leaveAdjustedHours: ''
}

const updateField = (name: string, value: string, form: CalculatorFormValues) => {
  const newForm = {
    ...form,
    [name]: value
  }

  return newForm
}

const getPotentialSalary = (values: CalculatorFormValues): PotentialSalary => {
  const { proposedSalary, billRate, wrapRate } = values

  return getCalculations(Number(proposedSalary), Number(billRate), Number(wrapRate))
}

const getPotentialSalaryRanges = (values: CalculatorFormValues): PotentialSalary[] => {
  const { proposedSalary, billRate, wrapRate } = values

  return getSalaryRanges(Number(proposedSalary), Number(billRate), Number(wrapRate))
}

const SalaryCalculator = () => {
  const [form, setForm] = useState<CalculatorFormValues>(formDefaults)
  const [errors, setErrors] = useState<CalculatorFormValues>(errorDefaults)

  const [potentialSalary, setPotentialSalary] = useState<PotentialSalary>(getPotentialSalary(form))
  const [potentialSalaryRanges, setPotentialSalaryRanges] = useState<PotentialSalary[]>(getPotentialSalaryRanges(form))

  const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
    const {
      target: { name, value }
    } = e

    try {
      await validations[name as keyof typeof validations].validate(value)

      setErrors(errorDefaults)
      setPotentialSalary(getPotentialSalary(form))
      setPotentialSalaryRanges(getPotentialSalaryRanges(form))
    } catch (e: any) {
      const defaultValue = formDefaults[name as keyof typeof formDefaults]

      const newForm = updateField(name, defaultValue, form)
      const newErrors = updateField(name, e.message, errors)

      handleChange(name, defaultValue)
      setErrors(newErrors)
      setPotentialSalary(getPotentialSalary(newForm))
      setPotentialSalaryRanges(getPotentialSalaryRanges(newForm))
    }
  }

  const handleSliderBlur = (e: FocusEvent<HTMLInputElement>) => {
    const {
      target: { name, value }
    } = e

    const newForm = updateField(name, value, form)

    setErrors(errorDefaults)
    handleChange(name, value)
    setPotentialSalary(getPotentialSalary(newForm))
    setPotentialSalaryRanges(getPotentialSalaryRanges(newForm))
  }

  const handleChange = (name: string, value: string) => {
    setForm(updateField(name, value, form))
  }

  return (
    <>
      <Header
        potentialSalary={potentialSalary}
        lowerSalaryRange={potentialSalaryRanges[1]?.salary}
        higherSalaryRange={potentialSalaryRanges[potentialSalaryRanges.length - 1]?.salary}
      />
      <div className="xl:flex xl:space-x-4">
        <Form
          values={form}
          errors={errors}
          handleChange={(e) => handleChange(e.target.name, e.target.value)}
          handleBlur={handleBlur}
          handleSliderBlur={handleSliderBlur}
        />
        <div className="relative h-full w-full overflow-auto">
          <ProfitChart proposedSalary={potentialSalary} data={potentialSalaryRanges} />
        </div>
      </div>
      <Heading className="mt-4">Range of Potential Profits</Heading>
      <SalaryTable data={potentialSalaryRanges} />
    </>
  )
}

export default SalaryCalculator
