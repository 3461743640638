import { useRouter } from 'next/navigation'

import { SparklesIcon, TableCellsIcon } from '@heroicons/react/24/solid'

import { Dialog, ZodForm } from '@/components'
import { Button } from '@/components/catalyst'
import { CreateProjectSchema, CreateProjectType } from '@/utils/schemas'

const createProjectFormFields = [
  {
    name: 'projectName',
    label: 'Project Name',
    config: {
      field: 'input' as const,
      type: 'text'
    }
  },
  {
    name: 'projectDescription',
    label: 'Project Description',
    config: {
      field: 'textarea' as const,
      rows: 4,
      resizable: false
    }
  }
]

const CreateProjectModal = ({
  projectName,
  handleClose
}: {
  projectName: string
  handleClose: (projectId?: string) => void
}) => {
  const router = useRouter()

  const createProject = async (data: CreateProjectType) => {
    const { projectName, projectDescription } = data

    try {
      const res = await fetch('/api/projects', {
        method: 'POST',
        body: JSON.stringify({
          projectName,
          projectDescription
        })
      })

      if (res.ok) {
        await res.json().then((json) => {
          handleClose()
          router.push(`/new-project/${json.data.projectId}`)
        })
      } else {
        const error = await res.json().then((json) => json.error)
        throw new Error(error)
      }
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Dialog
      open
      size="md"
      title="New Project"
      description=""
      actions={
        <>
          <Button
            className="smet-ai-button"
            onClick={() => {
              handleClose()
              router.push('/new-project')
            }}
          >
            Start with Assistant
            <SparklesIcon />
          </Button>
          <Button className="smet-secondary-button" type="submit" form="create-project-form">
            Self-guided
            <TableCellsIcon />
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <ZodForm
        id="create-project-form"
        fields={createProjectFormFields}
        defaultValues={{ projectName }}
        schema={CreateProjectSchema}
        onSubmit={createProject}
      />
    </Dialog>
  )
}

export default CreateProjectModal
