import { useState } from 'react'
import dynamic from 'next/dynamic'

import { Card, useUser } from '@/components'
import { resourcePlanType } from '@/components/resource-plan/self/schemas'

const DeepChat = dynamic(() => import('deep-chat-react').then((mod) => mod.DeepChat), {
  ssr: false
})

const ChatCard = ({
  history,
  resourcePlan,
  onMessage,
  handleRender
}: {
  history: any[]
  resourcePlan?: resourcePlanType
  onMessage: (message: any) => void
  handleRender: (ref: { submitUserMessage: (message: { text: string }) => void }) => void
}) => {
  const [threadId, setThreadId] = useState<string>('')

  const { companyProfile } = useUser()

  const handleRequestIntercept = (request: any) => {
    request.body = {
      ...request.body,
      resourcePlan,
      profile: companyProfile,
      threadId
    }

    return request
  }

  const handleResponseIntercept = (response: any) => {
    if (response.threadId) {
      setThreadId(response.threadId)
      return { text: '' }
    }

    return response
  }

  return (
    <div>
      <Card title="Assistant" cardStyles={{ borderColor: '#9D46F4' }} padding={false}>
        <div className="flex justify-center">
          <DeepChat
            className="flex-1 rounded-b-lg"
            style={{ border: 'none', width: 'unset' }}
            messageStyles={{
              html: { shared: { bubble: { backgroundColor: 'unset', padding: '0px' } } },
              default: {
                ai: { bubble: { backgroundColor: '#D8B5FB', color: '#1C2A3F' } },
                user: { bubble: { backgroundColor: '#E5E7EB', color: 'black' } }
              }
            }}
            textInput={{ placeholder: { text: 'Ask me something' } }}
            inputAreaStyle={{ backgroundColor: '#F5EDFE' }}
            submitButtonStyles={{
              submit: {
                svg: {
                  content:
                    "<?xml version='1.0' ?><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#9D46F4' class='size-6'><path fill-rule='evenodd' d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z' clip-rule='evenodd' /></svg>",
                  styles: {}
                }
              },
              alwaysEnabled: true,
              position: 'outside-right'
            }}
            connect={{ url: '/api/assistant/self-guided', stream: true }}
            errorMessages={{
              displayServiceErrorMessages: true
            }}
            history={history}
            requestInterceptor={handleRequestIntercept}
            responseInterceptor={handleResponseIntercept}
            onMessage={onMessage}
            onComponentRender={handleRender}
          />
        </div>
      </Card>
    </div>
  )
}

export default ChatCard
