'use client'

import { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { confirmSignIn, signIn } from 'aws-amplify/auth'

import { ChangeTemporaryPasswordModal, ZodForm } from '@/components'
import { Button, Heading, Text } from '@/components/catalyst'
import { LoginSchema, LoginType } from '@/utils/schemas'

const loginFormFields = [
  {
    name: 'email',
    label: 'Email',
    config: {
      field: 'input' as const,
      type: 'text'
    }
  },
  {
    name: 'password',
    label: 'Password',
    config: {
      field: 'input' as const,
      type: 'password'
    }
  }
]

const Login = () => {
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false)

  const router = useRouter()

  const login = async (data: LoginType) => {
    try {
      const res = await signIn({ username: data.email, password: data.password })

      if (res.isSignedIn) {
        router.push('/')
      } else if (res.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        throw new Error('You must first verify your account before logging in.')
      } else if (res.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        setIsChangingPassword(true)
      } else {
        throw new Error('An error has occurred. Please try again')
      }
    } catch (error) {
      alert(error)
    }
  }

  const confirmPasswordChange = async (newPassword: string) => {
    try {
      await confirmSignIn({ challengeResponse: newPassword })
      setIsChangingPassword(false)
      router.push('/')
    } catch (error) {
      alert(error)
    }
  }

  return (
    <>
      <Heading className="text-center" level={2}>
        Sign In
      </Heading>
      <ZodForm id="login-form" fields={loginFormFields} schema={LoginSchema} onSubmit={login} />
      <Button className="smet-primary-button" type="submit" form="login-form">
        Log in
      </Button>
      <div className="mt-4 flex flex-col justify-center">
        <div className="flex flex-col items-center">
          <Text>
            <strong>Having trouble logging in?</strong>
          </Text>
          <Link href="/auth/forgot-password">
            <Text>Reset your password</Text>
          </Link>
        </div>
        <div className="mt-2 flex flex-col items-center">
          <Text>
            <strong>Don&#39;t have an account?</strong>
          </Text>
          <Link href="/auth/register">
            <Text>Sign up</Text>
          </Link>
        </div>
      </div>
      <ChangeTemporaryPasswordModal
        isOpen={isChangingPassword}
        handleChange={confirmPasswordChange}
        handleClose={() => setIsChangingPassword(false)}
      />
    </>
  )
}

export default Login
