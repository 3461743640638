'use client'

import Head from 'next/head'

import { AuthProvider } from '@/components'

import 'rc-slider/assets/index.css'

import './globals.css'

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <html lang="en">
      <Head>
        <title>BDecisive</title>
      </Head>
      <body>
        <AuthProvider>{children}</AuthProvider>
      </body>
    </html>
  )
}

export default Layout
