function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
interface Tabs {
  key: string
  name: string
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

const Tabs = ({
  tabs = [],
  current = '',
  handleChange
}: {
  tabs: Tabs[]
  current: string
  handleChange: (tab: string) => void
}) => {
  return (
    <div>
      <div className="border-b border-gray-200">
        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <div
              key={tab.key}
              className={classNames(
                current === tab.key
                  ? 'border-smet-blue text-smet-blue'
                  : 'border-transparent text-smet-dark-2 cursor-pointer hover:border-smet-dark-2',
                'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium'
              )}
              onClick={() => handleChange(tab.key)}
            >
              <tab.icon
                aria-hidden="true"
                className={classNames(
                  current === tab.key ? 'text-smet-blue' : 'text-smet-dark-2 cursor-pointer',
                  '-ml-0.5 mr-2 size-5'
                )}
              />
              <span>{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}

export default Tabs
